<template>
  <div class="building-home">
    <header>
      <!-- 标题栏 -->
      <van-nav-bar
        :title="titleName"
        left-arrow
        @click-left="handleBlack"
        safe-area-inset-top
      >
        <template #right>
          <icon-svg
            iconClass="iconsearch"
            @click="loadSearchCase"
            style="height: 20px; width: 20px; color: '#333333'"
          />
        </template>
      </van-nav-bar>
      <!-- 户型、风格、阶段、区域菜单 -->
      <div class="tab-bar" v-if="tabIndex == 0">
        <div
          v-for="(item, index) in tabNames"
          :key="index"
          @click="tabAction(index)"
        >
          <div class="tab-name">
            {{ item }}
            <svg
              style="width: 16px; height: 16px; line-height: 44px"
              t="1649230988537"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5063"
            >
              <path
                d="M524 658.24l244.74-279.7a16 16 0 0 0-12-26.54H267.26a16 16 0 0 0-12 26.54L500 658.24a16 16 0 0 0 24 0z"
                fill="#333333"
                p-id="5064"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </header>
    <div class="building-home-body">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <!-- 工地单个列表 -->
          <div
            v-for="item in buildingList"
            :key="item"
            class="building-item"
            @click="goToDetails(item.buildId)"
          >
            <div
              class="building-img"
              :style="{ '--show': item.isTop == 0 ? `none` : `block` }"
            >
              <img
                :src="
                  item.headImgUrl
                    ? `${currentNet}/${item.headImgUrl}`
                    : `${defaultImg}`
                "
                alt=""
              />
            </div>
            <div class="building-info">
              <div class="info1" @click.stop>
                <div class="title-text">{{ limitWords(item.title, 20) }}</div>
                <icon-svg
                  iconClass="iconshare-2"
                  class="share"
                  style="width: 20px; height: 20px; margin-left: 6px"
                  @click="handleShare(item)"
                />
              </div>
              <div class="info2">
                <div class="room-describle">
                  {{ item.typeName }} | {{ item.area }}m² | {{ item.styleName }}
                </div>
                <div class="building-stage">
                  <svg
                    style="width: 12px; height: 12px; line-height: 12px"
                    fill="#289FFF"
                    t="1648619964035"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2453"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M511.914 63.99c-247.012 0-447.925 200.912-447.925 447.924s200.913 447.925 447.925 447.925 447.925-200.913 447.925-447.925S758.926 63.989 511.914 63.989z m0 831.687c-211.577 0-383.763-172.186-383.763-383.763 0-211.577 172.014-383.763 383.763-383.763s383.763 172.014 383.763 383.763-172.186 383.763-383.763 383.763z"
                      fill=""
                      p-id="2454"
                    ></path>
                    <path
                      d="M672.06 511.914H512.085v-223.79c0-17.718-14.277-32.167-31.995-32.167-17.717 0-31.994 14.45-31.994 32.167V544.08c0 17.717 14.277 31.994 31.994 31.994H672.06c17.718 0 32.167-14.277 32.167-31.994-0.172-17.89-14.621-32.167-32.167-32.167z"
                      fill=""
                      p-id="2455"
                    ></path>
                  </svg>
                  {{ item.stageName }}
                </div>
              </div>
              <div class="info3" @click.stop>
                <div class="location-text">
                  <svg
                    t="1648621241158"
                    fill="#BFBFBF"
                    style="width: 12px; height: 12px"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="3268"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M533.162667 846.813867a23.825067 23.825067 0 0 1-32.768 0C326.587733 682.0864 238.933333 550.570667 238.933333 448.477867a277.845333 277.845333 0 1 1 555.690667 0c0 102.126933-87.688533 233.608533-261.461333 398.336z m-246.613334-398.336c0 82.090667 76.288 199.2704 230.229334 348.091733 153.941333-148.821333 230.1952-266.001067 230.1952-348.0576a230.1952 230.1952 0 1 0-460.424534 0z m230.229334 87.3472a119.057067 119.057067 0 1 1 0-238.148267 119.057067 119.057067 0 0 1 0 238.148267z m0-47.650134a71.441067 71.441067 0 1 0 0-142.882133 71.441067 71.441067 0 0 0 0 142.882133z"
                      fill="#999999"
                      p-id="3269"
                    ></path>
                  </svg>
                  {{ item.region }} {{ item.apartmentName }}
                </div>
                <icon-svg
                  v-if="tabIndex == 1"
                  style="width: 16px; height: 16px"
                  iconClass="icontrash-2"
                  class="del"
                  @click="deleteArticle(item.buildId)"
                />
              </div>
              <div class="info4" v-if="tabIndex == 1">
                <div class="clue-num">
                  <icon-svg
                    iconClass="iconfile-text"
                    class=""
                    style="
                      width: 16px;
                      height: 16px;
                      color: #333;
                      margin-right: 6px;
                    "
                  />
                  线索{{ item.countOfClues ?? 0 }}
                </div>
                <div class="browse-num">
                  <icon-svg
                    iconClass="iconeye"
                    class=""
                    style="
                      width: 16px;
                      height: 16px;
                      color: #333;
                      margin-right: 6px;
                    "
                  />
                  浏览{{ item.countOfViews ?? 0 }}
                </div>
                <div class="forward-num">
                  <icon-svg
                    iconClass="iconshare"
                    class=""
                    style="
                      width: 16px;
                      height: 16px;
                      color: #333;
                      margin-right: 6px;
                    "
                  />
                  转发{{ item.countOfForward ?? 0 }}
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 底部工地列表、我的工地按钮 -->
    <footer>
      <van-tabbar
        v-model="tabIndex"
        active-color="#289fff"
        inactive-color="#333333"
        @click="bottomBarAction(tabIndex)"
      >
        <van-tabbar-item>
          <template #icon="">
            <svg
              t="1649215184397"
              style="height: 16px; width: 16px"
              :fill="tabIndex == 0 ? `#289fff` : `#333333`"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4216"
              width="200"
              height="200"
            >
              <path
                d="M358.4 448 153.6 448c-49.5 0-89.6-40.1-89.6-89.6L64 153.6c0-49.5 40.1-89.6 89.6-89.6l204.9 0c49.5 0 89.6 40.1 89.6 89.6l0 204.9C448 407.9 407.9 448 358.4 448z"
                p-id="4217"
              ></path>
              <path
                d="M870.4 448 665.6 448c-49.5 0-89.6-40.1-89.6-89.6L576 153.6c0-49.5 40.1-89.6 89.6-89.6l204.9 0c49.5 0 89.6 40.1 89.6 89.6l0 204.9C960 407.9 919.9 448 870.4 448z"
                p-id="4218"
              ></path>
              <path
                d="M358.4 960 153.6 960c-49.5 0-89.6-40.1-89.6-89.6L64 665.6c0-49.5 40.1-89.6 89.6-89.6l204.9 0c49.5 0 89.6 40.1 89.6 89.6l0 204.9C448 919.9 407.9 960 358.4 960z"
                p-id="4219"
              ></path>
              <path
                d="M870.4 960 665.6 960c-49.5 0-89.6-40.1-89.6-89.6L576 665.6c0-49.5 40.1-89.6 89.6-89.6l204.9 0c49.5 0 89.6 40.1 89.6 89.6l0 204.9C960 919.9 919.9 960 870.4 960z"
                p-id="4220"
              ></path>
            </svg>
            <!-- 
            <icon-svg
              iconClass="icon-APP1"
              :style="
                tabIndex == 0
                  ? `&quot;height: 24px; width: 24px; color: #289fff&quot;`
                  : `&quot;height: 24px; width: 24px; color: #333333&quot;`
              "
            /> -->
          </template>
          <span>工地列表</span>
        </van-tabbar-item>
        <van-tabbar-item>
          <template #icon="">
            <svg
              style="height: 20px; width: 20px"
              :fill="tabIndex == 1 ? `#289fff` : `#333333`"
              t="1649215629389"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="8420"
              width="200"
              height="200"
            >
              <path
                d="M946.5 505L560.1 118.8l-25.9-25.9c-12.3-12.2-32.1-12.2-44.4 0L77.5 505c-12.3 12.3-18.9 28.6-18.8 46 0.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8 12.1-12.1 18.7-28.2 18.7-45.3 0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204z m217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"
                p-id="8421"
              ></path>
            </svg>
          </template>
          <span>我的工地</span>
        </van-tabbar-item>
      </van-tabbar>
    </footer>
    <!-- 所用弹框 -->
    <div class="share-or-menu">
      <!-- 弹出分享框 -->
      <div class="share_box">
        <div
          class="wrapperPoster"
          id="saveposter"
          style="z-index: 9999"
          v-if="showOverlay"
        >
          <div
            class="poster-details"
            id="poster-details"
            v-if="showImg"
            ref="imageWrapper"
          >
            <img
              :src="
                shareItem.headImgUrl
                  ? `${currentNet}/${shareItem.headImgUrl}`
                  : `${defaultImg}`
              "
              crossOrigin="anonymous"
            />
            <div class="text-qrcode">
              <div class="user-info-tag">
                <div class="user-info">
                  <!-- <img :src="headImgUrl" /> -->
                  <div class="left-text">
                    <p>{{ shareItem.title }}</p>
                    <p>分享给朋友</p>
                    <!-- <p>{{ userForm.name }}</p> -->
                    <!-- <p>{{ userForm.merchantName }}</p> -->
                  </div>
                </div>
                <!-- <div class="user-tag">
                  <span
                    class="user-tag-item"
                    v-for="(it, index) in userTags"
                    :key="index"
                    >{{ it }}</span
                  >
                </div> -->
              </div>
              <div class="qrCodeUrl-all">
                <div class="qrCodeUrl-cls">
                  <img v-if="qrCodeUrl" :src="qrCodeUrl" />
                </div>
                <p>立即扫码</p>
                <p>了解更多内容</p>
              </div>
            </div>
          </div>
          <div v-else class="posterImg-hb">
            <img class="card-img" :src="imgSrc" ref="cardImgRef" />
          </div>
        </div>
        <van-share-sheet
          v-model:show="showShare"
          title="分享"
          :options="shareOptions"
          cancel-text="取消"
          @select="shareAction"
          safe-area-inset-bottom
        >
        </van-share-sheet>
      </div>
      <!-- 菜单选择 -->
      <van-overlay :show="showSelectMenu" @click="showSelectMenu = false">
        <div class="wrapper">
          <div class="menu-box" @click.stop>
            <div class="menu-items">
              <div
                class="menu-item"
                v-for="(item, index) in showMenuList"
                :key="index"
                @click="selectedMenuAction(item.typeId)"
                :style="
                  menuIndex == 0
                    ? {
                        '--menuItem':
                          selectedTypeList.indexOf(item.typeId) != -1
                            ? `#fff`
                            : `#bfbfbf`,
                        '--menuCol':
                          selectedTypeList.indexOf(item.typeId) != -1
                            ? `#289fff`
                            : `#fff`,
                      }
                    : {
                        '--menuItem':
                          selectedStyleList.indexOf(item.typeId) != -1
                            ? `#fff`
                            : `#bfbfbf`,
                        '--menuCol':
                          selectedStyleList.indexOf(item.typeId) != -1
                            ? `#289fff`
                            : `#fff`,
                      }
                "
              >
                {{ item.typeName }}
              </div>
            </div>
            <div class="menu-button">
              <div class="cancle" @click="resetMenu">重置</div>
              <div class="confirm" @click="confirmMenu">确定</div>
            </div>
          </div>
        </div>
      </van-overlay>
      <!-- 区域选择 -->
      <van-popup v-model:show="showRegion" round position="bottom">
        <van-picker
          title="请选择区域"
          :columns="regionColumns"
          @cancel="cancelSelectRegion"
          @confirm="selectRegionConfirm"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, nextTick, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getCityCode } from "@/utils/city_code";
import { Dialog, Toast } from "vant";
// import { findPosterImg } from "@/api/file";
import {
  copyStr,
  // loadImages,
  shareBuildToMiniProgram,
} from "@/utils/commonUtil";
import { createPosterAppShare, getQrCodeUrl, buildReprint } from "@/api/common";
// import { getUserCardByPoster } from "@/api/user/userCard";
import { posterShare } from "@/utils/posterShare";
import html2canvas from "html2canvas";
import {
  findBuildSiteByPageApp,
  findMyBuildSiteByPage,
  getBuildSiteDetailsUrl,
  deleteBuildSiteApp,
  initFindBuildSiteList,
} from "@/api/newBuildSite";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const imageWrapper = ref(null);
    const cardImgRef = ref(null);
    const router = useRouter();
    const route = useRoute();
    const data = reactive({
      currentNet: process.env.VUE_APP_WATCH_FILE_API,
      titleName: "工地列表",
      tabNames: ["户型", "风格", "区域"],
      defaultImg: require("@/assets/images/buildSite-default.png"),
      buildingList: [],
      regionColumns: [],
      selectedRegion: "",
      showShare: false,
      loading: false,
      finished: false,
      refreshing: false,
      showOverlay: false,
      showSelectMenu: false,
      showRegion: false,
      selectedTypeList: [],
      selectedStyleList: [],
      selectedMenu: [],
      page: 0,
      tabIndex: 0, //底部菜单
      //====post===
      userForm: {},
      userTags: [],
      headImgUrl: "",
      showSharePoster: false,
      shareJumpUrl: "",
      posterId: "",
      imgSrc: "",
      qrCodeUrl: "",
      posterName: "",
      posterPic: "",
      sharePosterImg: "",
      showImg: true,
      shareOptions: [],
      typeListName: "",
      styleListName: "",
      //=====end=======
      options: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
        { name: "分享海报", icon: require("@/assets/icons/share_poster.png") },
      ],
      mySiteOptions: [
        { name: "微信分享", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈分享", icon: require("@/assets/icons/friends2.png") },
      ],
      shareItem: {},
      menuList: {},
      showMenuList: [],
      menuIndex: 0,
    });
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });

    watch(
      () => data.showShare,
      (newVal, oldVal) => {
        console.log("新值：", newVal, "老值", oldVal);
        if (data.showShare === false) {
          data.showOverlay = false;
          data.showImg = true;
        }
      }
    );

    //初始化菜单数据
    const initMenu = async () => {
      let menuList = await initFindBuildSiteList();
      data.menuList = menuList;
      console.log("初始化菜单", data.menuList);
    };
    initMenu();
    //搜索
    const loadSearchCase = () => {
      router.push({
        path: "/searchBuildSite",
        query: {
          tabIndex: data.tabIndex,
        },
      });
    };
    const tabAction = (index) => {
      data.menuIndex = index;
      switch (index) {
        case 0:
          data.showSelectMenu = true;
          data.showMenuList = data.menuList["typeList"];
          break;
        case 1:
          data.showSelectMenu = true;
          data.showMenuList = data.menuList["styleList"];
          break;
        case 2:
          data.showRegion = true;
          break;
      }
    };
    const selectedMenuAction = (index) => {
      console.log(data.menuIndex);
      switch (data.menuIndex) {
        case 0:
          if (data.selectedTypeList.indexOf(index) == -1) {
            data.selectedTypeList.push(index);
          } else {
            var itIndex = data.selectedTypeList.indexOf(index);
            data.selectedTypeList.splice(itIndex, 1);
          }
          break;
        case 1:
          if (data.selectedStyleList.indexOf(index) == -1) {
            data.selectedStyleList.push(index);
          } else {
            var itIndex2 = data.selectedStyleList.indexOf(index);
            data.selectedStyleList.splice(itIndex2, 1);
          }
          break;
      }
    };
    const confirmMenu = () => {
      let TypeListName = "";
      let StyleListName = "";
      data.menuList["typeList"].forEach((e) => {
        data.selectedTypeList.forEach((i) => {
          if (i == e.typeId) {
            TypeListName += `${e.typeName},`;
          }
        });
      });

      data.menuList["styleList"].forEach((e) => {
        data.selectedStyleList.forEach((i) => {
          if (i == e.typeId) {
            StyleListName += `${e.typeName},`;
          }
        });
      });
      data.typeListName = TypeListName;
      data.styleListName = StyleListName;
      onRefresh();
      data.showSelectMenu = false;
    };
    const resetMenu = () => {
      switch (data.menuIndex) {
        case 0:
          data.selectedTypeList = [];
          break;
        case 1:
          data.selectedStyleList = [];
          break;
      }
      data.selectedRegion = ""; //重置地区
    };
    const limitWords = (e, num) => {
      if (e.length > num) {
        return e.substring(0, num) + "...";
      } else {
        return e;
      }
    };
    const handleBlack = () => {
      // router.back()
      if (route.params.addPoster === "true") {
        router.push("/createPoster");
        return;
      }
      if (route.params.fromPoster === "true") {
        router.push("/posterDetails");
        return;
      }
      if (route.params.fromPoster) {
        // router.push({ path: "/posterDetails" })
        router.back();
      } else {
        router.push({ path: "/" });
      }
    };
    //初始
    const onLoad = async () => {
      if (data.refreshing) {
        // console.log("刷新",data.refreshing);
        data.page = 0;
        data.buildingList = [];
        data.finished = false;
        data.refreshing = false;
      }
      data.page += 1;

      let apiData;
      if (data.tabIndex == 0)
        apiData = await findBuildSiteByPageApp({
          current: data.page,
          isMaterial: 1,
          typeName: data.typeListName,
          styleName: data.styleListName,
          region: data.selectedRegion,
        });
      // console.log("获取数据======================》");
      //  console.log("户型", data.typeListName)
      //  console.log("风格", data.styleListName)
      //  console.log("地区", data.selectedRegion)
      if (data.tabIndex == 1)
        apiData = await findMyBuildSiteByPage({
          current: data.page,
          isMaterial: 1,
        });
      if (apiData.records.length == 0) {
        data.finished = true;
      } else {
        apiData.records.forEach((element) => {
          data.buildingList.push(element);
        });
      }
      data.loading = false;
      Toast.clear();
      // console.log("最终长度", data.buildingList.length);
    };
    //切换底部菜单刷新页面
    const bottomBarAction = (e) => {
      data.tabIndex = e;
      data.page = 0;
      data.buildingList = [];
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      if (data.tabIndex == 1) data.titleName = "我的工地";
      else data.titleName = "工地列表";
      onLoad();
    };
    const onRefresh = () => {
      data.loading = true;
      data.refreshing = true;
      onLoad();
    };
    //================分享start=====================================
    const loadPoster = () => {
      savePoster();
      setTimeout(() => {
        Toast.clear();
      });
    };
    const handleShare = (e) => {
      data.shareItem = e;
      console.log(data.shareItem, "分享");
      data.shareOptions = data.options;
      data.sharePosterImg = data.shareItem.headImgUrl
        ? `${data.currentNet}/${data.shareItem.headImgUrl}`
        : "https://wca.ujiaku.com/upload/wx/buildSite_default.png";
      data.posterPic = data.shareItem.headImgUrl
        ? `${data.currentNet}/${data.shareItem.headImgUrl}`
        : "https://wca.ujiaku.com/upload/wx/buildSite_default.png";
      data.showOverlay = false;
      data.showShare = true;

      let params = {
        sourceId: data.shareItem.buildId, //分享的id
        sourceType: 4, //来源类别 1-文章 2-案例 3-视频 4-工地
      };
      getQrCodeUrl(params).then((res) => {
        data.shareJumpUrl = res.jumpUrl;
        if (res.qrCodeUrl) {
          data.qrCodeUrl = res.qrCodeUrl;
        }
        data.showSharePoster = true;
      });
    };
    const shareAction = (e) => {
      console.log(e, "dianji");
      if (e.name === "微信分享" || e.name === "朋友圈分享") {
        if (data.showSharePoster === true) {
          Toast.loading({
            message: "正在\n打开微信...",
            forbidClick: true,
            duration: 3000,
            className: "posterToast",
          });
          let p = {
            sourceId: data.shareItem.buildId, //分享的id
            sourceType: 4, //来源类别 1-文章 2-案例 3-视频 4-工地
            jumpUrl: data.shareJumpUrl,
          };
          createPosterAppShare(p).then((res) => {
            console.log("创建二海报", res);
          });
          let param = {
            imgSrc: data.imgSrc,
            id: data.shareItem.buildId,
            bigPosterImgUrl: data.sharePosterImg,
          };
          console.log(
            "bushihaibao==================================>",
            data.imgSrc
          );
          setTimeout(() => {
            posterShare(e, param);
          });
        }
      } else if (e.name === "复制链接") {
        getBuildSiteDetailsUrl(data.shareItem.buildId).then((res) => {
          copyStr(res);
          Toast.success("复制成功");
          data.showShare = false;
        });
      } else if (e.name === "分享海报") {
        if (data.showSharePoster === true) {
          Toast.loading({
            message: "生成海报中...",
            forbidClick: true,
            duration: 0,
            className: "posterToast",
          });
          data.showOverlay = true;
          data.shareOptions = [];
          data.shareOptions = data.mySiteOptions;

          // let arr = [];
          // arr.push(data.posterPic);
          // arr.push(data.qrCodeUrl);
          // arr.push(data.headImgUrl);
          // loadImages(arr, loadPoster);
          setTimeout(() => {
            loadPoster();
          }, 200);
        }
      } else {
        buildReprint({ busiId: data.shareItem.buildId });
        shareBuildToMiniProgram(data.shareItem, e);
      }
    };
    //画图
    const savePoster = () => {
      console.log("保存");
      data.showOverlay = true;
      // html2canvasChange()
      nextTick(() => {
        // var canvas2 = document.createElement("canvas");
        console.log(imageWrapper.value);
        let _canvas = imageWrapper.value;
        const box = window.getComputedStyle(_canvas);
        // DOM 节点计算后宽高
        const width = parseInt(box.width, 10);
        const height = parseInt(box.height, 10);
        // 获取像素比
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        html2canvas(_canvas, {
          useCORS: true,
          scrollY: 0,
          scrollX: 0,
          background: "#ffffff",
          height: document.getElementById("saveposter").scrollHeight,
          windowHeight: document.getElementById("saveposter").scrollHeight,
          canvas,
        }).then((canvas) => {
          var context = canvas.getContext("2d");
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          let dataURL = canvas.toDataURL("image/png");
          data.showImg = false;
          nextTick(() => {
            cardImgRef.value.style.width = width + "px";
            cardImgRef.value.style.height = height + "px";
            data.imgSrc = dataURL;
            data.showImg = false;
          });
        });
      });
    };
    const DPR = () => {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
      }
      return 1;
    };
    //================分享end=====================================
    //删除工地
    const deleteArticle = (e) => {
      Dialog.confirm({
        title: "删除",
        message: "是否删除此工地",
      })
        .then(async () => {
          // on confirm
          console.log("shanchu");
          let result = await deleteBuildSiteApp(e);
          if (result) {
            onRefresh();
          }
        })
        .catch(() => {
          console.log("quxiao");

          // on cancel
        });
    };
    //初始地区
    const loadCityCode = () => {
      data.regionColumns = [];
      let area = getCityCode();
      data.regionColumns = area;
    };
    loadCityCode();
    //取消选择
    const cancelSelectRegion = () => {
      data.showRegion = false;
      data.selectedRegion = "";
      onRefresh();
    };
    //确认选择地区
    const selectRegionConfirm = (item) => {
      data.selectedRegion = "";
      item.forEach((item) => {
        data.selectedRegion += `${item.text} `;
      });
      onRefresh();
      data.showRegion = false;
    };
    //点击详情页
    const goToDetails = (buildId) => {
      console.log(buildId);
      router.push({
        path: "/siteDetail",
        name: "siteDetail",
        query: {
          buildId: buildId,
        },
        params: {
          fromPoster:
            route.params.fromPoster === "true" ? route.params.fromPoster : "",
          addPoster:
            route.params.addPoster === "true" ? route.params.addPoster : "",
        },
      });
    };
    return {
      imageWrapper,
      cardImgRef,
      savePoster,
      DPR,
      ...toRefs(data),
      tabAction,
      confirmMenu,
      resetMenu,
      loadSearchCase,
      limitWords,
      handleBlack,
      onRefresh,
      onLoad,
      bottomBarAction,
      handleShare,
      shareAction,
      deleteArticle,
      selectedMenuAction,
      loadCityCode,
      selectRegionConfirm,
      cancelSelectRegion,
      goToDetails,
    };
  },
};
</script>

<style lang="scss" >
.posterToast {
  //color: #289fff;
  z-index: 1000000001 !important;
}
.building-home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  //   分享
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      //    position: relative;
      //    transform: translateX(50%);
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
  //遮罩层
  .wrapperPoster {
    //margin-top: 100px;
    height: 400px;
    overflow-x: hidden;
    //position: absolute;
    position: fixed;
    top: 60px;
    //bottom: 0;
    left: 0;
    right: 0;
    .poster-details {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      overflow-x: hidden;
      > img {
        width: 250px;
        display: block;
      }
      .text-qrcode {
        background-color: #ffff;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        flex: 1;

        .user-info-tag {
          .user-info {
            display: flex;
            align-items: center;
            img {
              border-radius: 20px;
              width: 40px;
              height: 40px;
              object-fit: cover;
              flex-shrink: 0;
            }
            .left-text {
              text-align: left;
              //flex: 1;
              margin-left: 4px;
              p {
                margin: 0;
                padding: 0;
              }
              p:nth-child(1) {
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                color: #333333;
              }
              p:nth-child(2) {
                font-size: 10px;
                color: #333333;
                //line-height: 20px;
              }
            }
          }
          .user-tag {
            display: flex;
            flex-wrap: wrap;
            .user-tag-item {
              margin-top: 5px;
              margin-right: 5px;
              //padding-left: 5px;
              padding: 2px 5px;
              color: #289fff;
              background-color: #e6ecff;
              font-size: 9px;
              border-radius: 4px;
            }
          }
        }

        .qrCodeUrl-all {
          //position: relative;
          //text-align: center;
          //height: 60px;
          border-left: 1px solid #f1f3f6;
          padding-left: 18px;
          margin-left: 8px;
          flex-shrink: 0;
          .qrCodeUrl-cls {
            //width: 60px;
            //height: 60px;
            background-color: #ffffff;
            border-radius: 30px;
            //position: relative;
            //top: -33px;
            //text-align: center;
            img {
              width: 60px;
              //border-radius: 25px;
              //position: relative;
              //margin-top: 50%;
              //transform: translateY(-50%);
            }
          }
          > p {
            //position: relative;
            text-align: center;
            font-size: 10px;
            font-weight: 400;
            color: #666666;
            margin: 0;
            //top: -40px;
          }
        }
      }
    }
    .posterImg-hb {
      height: 400px;
      overflow-x: hidden;
    }
  }

  .building-home-body {
    padding-bottom: 60px;
    .building-item {
      padding: 16px 16px 2px 16px;
      .building-img {
        position: relative;
        height: 160px;
        border-radius: 4px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .building-img::before {
        display: var(--show);
        position: absolute;
        height: 18px;
        font-size: 12px;
        line-height: 20px;
        padding: 0 8px;
        color: #fff;
        content: "置顶";
        left: 0;
        top: 0;
        background-color: #ff6e01;
        border-radius: 4px 0 4px 0;
      }

      .building-info {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        > div {
          margin-top: 4px;
          display: flex;
          flex-direction: row;
        }
        .info1 {
          font-size: 14px;
          display: flex;
          flex-direction: row;
          .title-text {
            flex: 1;
            text-align: start;
          }
        }
        .info2 {
          display: flex;
          flex-direction: row;
          > div {
            flex: 1;
            font-size: 12px;
            text-align: start;
          }
          .room-describle {
            color: #666;
          }
          .building-stage {
            color: #289fff;
          }
        }
        .info3 {
          display: flex;
          flex-direction: row;
          .location-text {
            text-align: start;
            font-size: 12px;
            color: #bfbfbf;
            flex: 1;
          }
        }

        .info4 {
          padding-top: 16px;
          font-size: 12px;
          color: #999;
          justify-content: space-evenly;
        }
      }
    }
  }

  //选择框
  .tab-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #f1f3f6;
    > div {
      flex: 1;
      height: 44px;
      .tab-name {
        line-height: 44px;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .van-hairline--bottom::after {
    border: 0 solid #fff;
  }
  //选择弹框
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .menu-box {
      position: relative;
      top: -30%;
      background-color: #fff;
      width: 358px;
      display: flex;
      flex-direction: column;
      .menu-items {
        margin: 29px 23px 40px 23px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .menu-item {
          border-radius: 4px;
          padding: 4px 12px;
          border: #bfbfbf solid 1px;
          background-color: var(--menuCol);
          color: var(--menuItem);
          margin-right: 4px;
          margin-bottom: 4px;
        }
      }
      .menu-button {
        justify-content: flex-end;
        flex-direction: row;
        color: #fff;
        text-align: center;
        display: flex;
        font-size: 12px;
        padding: 0 23px 17px 0;
        .cancle {
          background-color: #bfbfbf;
          border-radius: 4px;
          padding: 4px 24px;
          margin-right: 8px;
        }
        .confirm {
          background-color: #ff6e01;
          border-radius: 4px;
          padding: 4px 24px;
        }
      }
    }
  }
}
</style>